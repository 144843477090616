import AppRouter from './AppRouter';
import React from 'react';
import './App.scss';

const App = () => {
  const header = React.useRef()

  return (
    <div className="App">     
      <div className="navBar">
        <div id="navContents">
            <a href="/" id="main">
              <div id="name">
                  <h1>Chae</h1>
                  <h1>Ryeong</h1>
                  <h1>Oh</h1>
              </div>
            </a>
          <div id="nav">
            <p>&#123;</p>
            <div id="navbuttons">
              <a href="/about" id="workref">
                  About
              </a>
              <a href="/works" id="workref">
                  Works
              </a>
            </div>
            <p>&#125;</p>
          </div>
        </div>
      </div> 


      <AppRouter header={header}/> 

      <div className="footer">2022, by Chaeryeong Oh. All rights reserved.</div>     
    </div>

    
  );
};

export default App;
