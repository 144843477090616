import { React } from "react";
import contents from "./contents.js";


const Works = (props) => {   
    return (
        <div className = "worklist">
        {
        Object.keys(contents).map(title=>{
            return (
                <div className="works">
                    <a href={"works/"+title}>
                    <img src={contents[title].thumbnail.still} alt={title}></img>
                    <div className="tag">
                    {
                        contents[title].tag
                        ? contents[title].tag.map((item) => {
                            return <button>{item}</button>;
                            })
                        : {}
                    }
                    </div>
                    <div className="name">{contents[title].name}</div>
                    </a>
                </div>
            );
        })}
        </div>
    );
};

export default Works;
