import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/about/about"
import Works from "./pages/works/works"
import Main from "./pages/main/main"
import Work from "./pages/works/work"
import './App.scss';

const AppRouter = (props) => {
  return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/React"></Route>
          <Route
            exact path="/"
             element = {<Main header={props.header}/>}
          ></Route>
          <Route
            exact path="/about"
            element = {<About header={props.header}/>}
          ></Route>
          <Route
            exact path="/works"
            element = {<Works header={props.header}/>}
          ></Route>
          <Route
            exact path="/works/:work"
            element = {<Work header={props.header}/>}
          ></Route>
          <Route exact path="*"></Route>
        </Routes>
      </BrowserRouter>
  );
};

export default AppRouter;
